import de from './de.json';
import en from './en.json';
// eslint-disable-next-line camelcase
import es_xl from './es-xl.json';
import es from './es.json';
// eslint-disable-next-line camelcase
import fr_ca from './fr-ca.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import pt from './pt.json';
import qat from './qat.json';

// eslint-disable-next-line camelcase
export default { de, en, es_xl, es, it, fr_ca, fr, ja, pt, qat };
