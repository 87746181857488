/* eslint-disable react/destructuring-assignment */
import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import translations from '../locales';

const intlCache = createIntlCache();
let intl;

function getUserLocale () {
	const { languages } = navigator;
	const supportedCultures = ['de', 'en', 'es-xl', 'es', 'fr-ca', 'fr', 'it', 'ja', 'pt'];

	// All correspond to es-xl culture code (pulled from applocalization-express)
	const latamCultures = ['es-ar', 'es-bo', 'es-cl', 'es-co', 'es-cr', 'es-do', 'es-ec', 'es-gt', 'es-hn',
		'es-mx', 'es-ni', 'es-pa', 'es-pe', 'es-pr', 'es-py', 'es-sv', 'es-uy', 'es-ve'];

	const displayLang = 'en'; // Fallback to English
	for (let i = 0; i < languages.length; i += 1) {
		const clientLang = languages[i].split('-')[0];
		if (supportedCultures.includes(languages[i])) {
			return languages[i]; // Full culture code support
		}

		if (latamCultures.includes(languages[i])) {
			return 'es-xl'; // Check if culture is part of es-xl
		}

		if (supportedCultures.includes(clientLang)) {
			return clientLang; // Support for just the language
		}
	}
	return displayLang;
}

function IntlApp (props) {
	let locale = getUserLocale();
	if (props.language !== locale && props.langChanged) {
		locale = props.language;
	}
	const messages = translations[locale.replace('-', '_')];

	intl = createIntl({
		locale,
		messages
	}, intlCache);

	return (
		<RawIntlProvider value={intl}>
			{props.children}
		</RawIntlProvider>
	);
}

IntlApp.propTypes = {
	children: PropTypes.object.isRequired,
	language: PropTypes.string.isRequired,
	langChanged: PropTypes.bool.isRequired
};

export function useIntlForAction () {
	if (!intl) {
		// eslint-disable-next-line no-console
		console.warn('intl accessed before dictionary loaded');
		intl = createIntl({}, intlCache);
	}

	return intl;
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps (state, ownProps) {
	return {
		language: state.language,
		langChanged: state.langChanged
	};
}

export default hot(connect(mapStateToProps)(IntlApp));
